<template>
  <div class="right-content">
    <div class="top">
      <div class="cate">
        <div class="item" :class="{active:this.cid==0}" @click="categoryChange(0)">全部</div>
        <div class="item" v-for="(m,index) in type_data" :key="index" :class="{active:this.cid==m.id}" @click="categoryChange(m.id)">{{ m.name }}</div>
      </div>
    </div>
    <!--  列表  -->
    <div class="my-folder-list" v-loading="loading" element-loading-background="rgba(245,245,245,1)">
      <ul>
<!--        <li class="btn-create-folder">
          <img src="/static/iconImg/icon-add.png" alt="">
          <span>新建文件夹</span>
        </li>-->
        <li v-for="(m,index) in data_list" :key="index">
          <div class="pics">
            <router-link :to="'/detail/'+m.article.id">
              <img :src="m.article.img" :alt="m.article.title">
            </router-link>
          </div>
          <div class="info">
            <h3>
              <router-link :to="'/detail/'+m.article.id">{{ m.article.title }}</router-link>
            </h3>
            <div class="viewer">
              <img src="/static/iconImg/icon-view02.png" alt=""><span>{{ m.article.view }}</span>
            </div>
          </div>
          <div class="operate">
            <router-link :to="'/detail/'+m.article.id">查看</router-link>
            <span>|</span>
            <a href="javascript:;" @click="cancelFavorite(m.id)">删除</a>
          </div>
        </li>
      </ul>
    </div>
    <!-- 页码-->
    <div class="pagination">
      <ul>
        <li @click="prevPage" :class="{hide:current_page === 1}">
          <a class="arrow" href="javascript:;">
            <img src="static/iconImg/icon-left-arrow.png" alt="">
          </a>
        </li>
        <li v-for="page in last_page" :key="page" @click="pagination(page)">
          <a href="javascript:;"  :class="{active: current_page === page}">{{page}}</a>
        </li>
        <li @click="nextPage" :class="{hide:current_page === last_page || last_page===0}">
          <a class="arrow" href="javascript:;">
            <img src="static/iconImg/icon-right-arrow.png" alt="">
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
//import article from "@/api/article";
import user from "@/api/user";
import article from "@/api/article";

export default {
  name: "folder",
  components:{},
  data() {
    return {
      keyword:'',
      per_page: 12,
      total: 0,
      current_page: 1,
      last_page: 0,
      data_list: [],
      codeUrl: '',
      cid: 0,
      type_data:[],
      loading:false
    }
  },
  created() {
    this.getCategoryNameList();
    this.getList();
  },
  methods: {
    //取消收藏
    cancelFavorite(id){
      user.cancelFavorite({id:id}).then(res=>{
        if(res){
          this.$message.success(res.msg);
          this.getList();
        }
      })
    },
    //分页
    pagination(page) {
      if (this.current_page == page) {
        return false;
      } else {
        this.current_page = page;
        this.getList();
      }
    },
    //上一页
    prevPage() {
      if (this.current_page <= 1) {
        return false;
      } else {
        this.current_page = this.current_page-1;
        this.getList();
      }
    },
    //下一页
    nextPage() {
      if (this.current_page >= this.last_page) {
        return false;
      } else {
        this.current_page = this.current_page+1;
        this.getList();
      }
    },
    //首页 分类选项卡
    getCategoryNameList() {
      article.getCategoryNameList({
        ident: 'type'
      }).then(res => {
        if (res) {
          this.type_data = res.data;
        }
      })
    },
    //改变分类
    categoryChange(cid){
      this.cid = cid;
      this.getList();
    },
    //调取 列表数据
    getList() {
      this.loading = true
      user.getFavoriteList({
        keyword: this.keyword,
        cid: this.cid,
        pagesize:this.per_page,
        page: this.current_page
      }).then(res => {
        if (res) {
          this.data_list = res.data.data;
          this.per_page = res.data.per_page;
          this.total = res.data.total;
          this.current_page = res.data.current_page;
          this.last_page = res.data.last_page;
          this.loading = false
        } else {
          console.log(res);
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
</style>